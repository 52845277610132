<template>
  <div id="document-review-list">
    <!-- dialog -->
    <v-card>
      <delete-confirmation
        v-model="isDeleteDialogActive"
        :selected-item="selectedItem"
        @delete-confirmation="submitDelete"
        @refetch-data="refreshPage"
      ></delete-confirmation>

      <v-dialog
        v-model="isNewDocumentFormActive"
        persistent
        max-width="75%"
      >
        <document-add-edit
          v-if="isNewDocumentFormActive"
          :is-document-form-active="isNewDocumentFormActive"
          :doc="selectedDocument"
          :is-edit="true"
          @update-dialog="updateDialog"
          @refetch-data="refreshPage"
        ></document-add-edit>
      </v-dialog>
    </v-card>

    <v-dialog
      v-if="selectedDocument != null"
      v-model="renderDialog"
      persistent
      max-width="75%"
    >
      <div>
        <v-card>
          <v-card-text>
            <div>
              <v-row
                justify="center"
              >
                <v-col
                  md="auto"
                >
                  <v-btn
                    color="error"
                    @click="updateRenderDialog('render')"
                  >
                    Close
                  </v-btn>
                </v-col>
                <v-spacer></v-spacer>
                <v-col
                  md="auto"
                >
                  <v-btn
                    color="warning"
                    @click="isDeleteDialogActive = !isDeleteDialogActive"
                  >
                    Delete
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
        </v-card>
        <v-card>
          <div>
            <document-add-edit
              :is-document-form-active="true"
              :doc="selectedDocument"
              :pdf-review="selectedItem"
              :is-edit="true"
              @delete-confirmation="submitDelete"
              @update-dialog="updateRenderDialog('render')"
              @refetch-data="refreshPage"
            ></document-add-edit>
          </div>
          <div>
            <hr />
            <VuePdfEmbed :source="selectedDocument" />
          </div>
        </v-card>
      </div>
    </v-dialog>

    <v-card>
      <v-toolbar>
        <!-- search -->
        <v-card-text class="d-flex align-center flex-wrap pb-0">
          <v-text-field
            v-model="searchQuery"
            placeholder="Search"
            outlined
            hide-details
            dense
            :prepend-inner-icon="icons.mdiMagnify"
            class="me-3 mb-4"
          >
          </v-text-field>

          <div class="d-flex align-center flex-wrap">
            <v-btn
              v-model="isNewDocumentFormActive"
              color="primary"
              class="mb-4 me-3"
              @click="updateDialog(null), isEdit = true"
            >
              <v-icon>{{ icons.mdiPlus }}</v-icon>
              <span>Add New Document</span>
            </v-btn>
          </div>
        </v-card-text>
      </v-toolbar>
      <!-- table -->
      <v-data-table
        v-model="selectedRows"
        :headers="documentReviewListColumns"
        :items="pdfList"
        :itemsPerPage="15"
        multiSort
        :search="searchQuery"
        :loading="loading"
      >
        <template #[`item.docTitle`]="{item}">
          <v-btn
            v-if="item.pdfPath !== null && item.pdfPath !== undefined"
            v-model="renderDialog"
            color="primary"
            text
            @click="selectedItem = item, selectedDocument = item.pdfPath, updateRenderDialog('render'), isEdit = false"
          >
            <v-icon
              class="me-2"
            >
              {{ icons.mdiCheckAll }}
            </v-icon>
            {{ item.docTitle }}
          </v-btn>
          <span v-else>
            {{ item.docTitle }}
          </span>
        </template>
        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-btn
            text
            x-small
            @click="selectedItem = item, isDeleteDialogActive = !isDeleteDialogActive"
          >
            <v-icon
              size="20"
              class="me-2"
            >
              {{ icons.mdiDeleteOutline }}
            </v-icon>
            <span>Delete</span>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'
import {
  mdiAccountOutline,
  mdiAlertCircleOutline,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiExportVariant,
  mdiFileDocumentOutline,
  mdiOfficeBuilding,
  mdiPlus,
  mdiFileDocumentEditOutline,
  mdiAccountEditOutline,
  mdiAccountLockOutline,
  mdiFormTextboxPassword,
  mdiMagnify,
  mdiCheckAll,
} from '@mdi/js'

import taskService from '@/services/TaskService'
import useTaskService from '@/store/useTaskService'


import DeleteConfirmation from '@/components/validation/DeleteConfirmation.vue'
import DocumentAddEdit from './DocumentAddEdit.vue'
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'

export default {
  components: { DeleteConfirmation, DocumentAddEdit, VuePdfEmbed },
  mounted() {
    // get pdfs
    this.fetchPDFs()
  },
  setup() {
    const TASK_APP = 'app-task'

    // Register module
    if (!store.hasModule(TASK_APP)) store.registerModule(TASK_APP, taskService)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(TASK_APP)) store.unregisterModule(TASK_APP)
    })

    const {
      fetchPDFs,
      pdfList,
      documentReviewListColumns,
      deletePDF,

      searchQuery,
      statusFilter,
      loading,
      options,
      selectedRows,
    } = useTaskService()


    const isEdit = ref(false)
    const isNew = ref(false)
    const selectedItem = ref({})
    const isDeleteDialogActive = ref(false)
    const isDocumentFormActive = ref(false)
    const isNewDocumentFormActive = ref(false)
    const currentTab = ref(6)
    const renderDialog = ref(false)
    const selectedDocument = ref(null)


    return {
      fetchPDFs,
      pdfList,
      documentReviewListColumns,
      deletePDF,

      searchQuery,
      statusFilter,
      loading,
      options,
      selectedRows,

      isDocumentFormActive,
      isNewDocumentFormActive,
      selectedItem,
      isEdit,
      isNew,
      isDeleteDialogActive,
      currentTab,
      renderDialog,
      selectedDocument,

      icons: {
        mdiAccountOutline,
        mdiAlertCircleOutline,
        mdiDeleteOutline,
        mdiDotsVertical,
        mdiExportVariant,
        mdiFileDocumentOutline,
        mdiOfficeBuilding,
        mdiPlus,
        mdiFileDocumentEditOutline,
        mdiAccountEditOutline,
        mdiAccountLockOutline,
        mdiFormTextboxPassword,
        mdiMagnify,
        mdiCheckAll,
      },

    }
  },

  methods: {
    log(msg, item) {
      console.log(msg, item)
    },
    selectItem(item) {
      this.selectedItem = item
      console.log(this.selectedItem)
    },
    submitDelete(item) {
      console.log('Delete', item)
      if(item == 'PDF'){
        this.deletePDF(this.selectedItem.id)
      }
      else{
        // Delete Document
        this.deletePDF(item.id)
      }
    },
    refreshPage() {
      this.selectedItem = ref()
      this.fetchPDFs()
      this.$watch('pdfList', () => {
        console.log(this.pdfList)
      })
    },
    updateRenderDialog(dialog) {
      if (dialog === 'render') {
        if (this.renderDialog) {
          this.selectedDocument = {}
          this.selectedItem = {}
        }
        if (this.selectedDocument == null) {
          this.renderDialog = false
        }
        this.renderDialog = !this.renderDialog
      }
    },
    updateDialog(status) {
      if (status == null) {
        this.selectedItem = {}
        this.selectedDocument = null
      }
      this.isNewDocumentFormActive = !this.isNewDocumentFormActive
    },
  },
}
</script>
